<template>
  <TextField
      v-bind="$props"
      v-on="$listeners"
      :placeholder="$t('jammers.phoneNumber')"
      :rules="rules"
      v-model="model"
  />
</template>
<script>
import TextField from '@/components/TextField';
import { getFormattedPhone } from '@/lib';

export default {
  components: {
    TextField
  },
  props: {
    value: {
      type: String,
      default: ''
    },
    rules: {
      types: Object
    },
    disabled: {
      type: Boolean
    },
    label: {
      type: String,
      default: ''
    }
  },
  computed: {
    model: {
      get() {
        return getFormattedPhone(this.value);
      },
      set(value) {
        this.$emit('input', value);
      }
    }
  },
  data () {
    return {}
  }
}
</script>
