<template>
  <v-container class="pa-0">
    <v-btn v-if="jammerStatus !== 'Active' && jammerStatus !== 'invited' && jammerStatus !== 'deleted'" class="btn btn--accent" block color="text" @click="$emit('invite')">
      <span class="status__text btn__text--white">Invite</span>
    </v-btn>
    <v-btn v-if="jammerStatus === 'Active'" class="status disable-events" outlined block color="secondaryBackground">
      <span class="status__text status__text--green">Active</span>
    </v-btn>
    <v-btn v-if="jammerStatus === 'invited'" class="status disable-events" block outlined color="text">
      <span class="status__text">Invite Sent</span>
    </v-btn>
    <v-btn v-if="jammerStatus === 'consent'" class="status disable-events" block outlined color="text">
      <span class="status__text">Waiting confirmation</span>
    </v-btn>
    <v-btn v-if="jammerStatus === 'deleted'" class="status disable-events" block outlined color="text">
      <span class="status__text">Deleted</span>
    </v-btn>
  </v-container>
</template>

<script>
export default {
  name: 'JammerStatusBtn',
  props: {
    status: {
      type: String,
    }
  },
  data() {
    return {
      jammerStatus: this.status,
    }
  },
}
</script>

<style scoped>
.status {
  border-radius: 10px;
}
.status__text {
  font-size: 1.3rem;
  font-weight: bold;
  letter-spacing: 0;
}
.status__text--green {
  color: var(--v-secondaryBackground-base);
}
.status__text--white {
  color: white;
}
.v-btn.status {
  height: 4rem;
  width: 7.5rem;
}
.disable-events {
  pointer-events: none
}
</style>
