<template>
  <v-form
      @submit.prevent="submit"
      ref="form"
  >
  <v-row class="d-flex align-center">
    <v-col class="d-lg-none" cols="6">
      <div class="field-number field-number--underlined">
        {{ $t('jammers.newJammer') }}{{ k + 1}}
      </div>
    </v-col>
    <v-col class="d-flex justify-end d-lg-none" cols="6">
      <v-btn @click="deleteCollaborator" text>
        <span class="remove__text">{{ $t('jammers.removeJammerMobile') }}</span>
      </v-btn>
    </v-col>
    <div class="field-number d-none d-lg-block mr-3">{{ k + 1 }}.</div>
    <v-col lg="auto" md="6" cols="12" class="px-1">
      <TextField
          ref="firstName"
          autofocus="autofocus"
          :placeholder="$t('jammers.firstName')"
          v-model="input.firstName"
          :rules="[v => !!v || 'First name is required']"
      />
    </v-col>
    <v-col lg="2" md="6" cols="12" class="px-1">
      <TextField
          :placeholder="$t('jammers.lastName')"
          v-model="input.lastName"
          :rules="[v => !!v || 'Last name is required']"
      />
    </v-col>
    <v-col lg="3" md="6" cols="12" class="px-1">
      <PhoneField
          :placeholder="$t('jammers.phoneNumber')"
          v-model="input.phoneNumber"
          :rules="[rules.required, rules.phone]"
      />
    </v-col>
    <v-col lg="2" md="6" cols="12" class="px-1">
      <JammerStatusBtn
          @invite="clickHandler"
          :status="input.status"
          :disabled="true"
      />
    </v-col>
    <v-col class="d-flex align-center" cols="1">
      <v-btn class="d-none d-lg-block" @click="deleteCollaborator">
        <span class="remove__text" v-html="$t('jammers.removeJammer')"></span>
      </v-btn>
    </v-col>
  </v-row>
  </v-form>
</template>
<script>
import TextField from '@/components/TextField';
import JammerStatusBtn from '@/components/JammerStatus';
import PhoneField from '@/components/PhoneField';
import { validatePhone } from '@/lib/';

  export default {
    components: {
      TextField,
      JammerStatusBtn,
      PhoneField
    },
    props: {
      input: {
        type: Object,
        default: () => ({ firstName: '', lastName: '', phoneNumber: '', status: '' })
      },
      k: {
        type: Number
      }
    },
    data() {
      return {
        rules: {
          required: value => !!value || this.$t('login.required'),
          phone: value => validatePhone(value) || this.$t('login.badPhone')
        }
      }
    },
    methods: {
      deleteCollaborator() {
        this.$emit('remove', this.k);
      },
      clickHandler() {
        if (this.$refs.form.validate()) {
          this.$emit('invite', this.input);
        }
      }
    }
  }
</script>
<style scoped>
.remove__text {
  color: var(--v-primaryWarning-base);
  text-transform: uppercase;
  text-align: center;
  font-weight: bold;
  text-decoration: underline;
  font-size: 1.2rem;
}
.field-number {
  font-size: 2rem;
  font-weight: bold;
}
.field-number--underlined {
  text-decoration: underline;
}
.input__field >>> .v-text-field__slot {
  font-size: 1.2rem;
  padding-left: 0;
  padding-right: 0;
}
@media only screen and (max-width: 960px) {
  .field-number {
    font-size: 1.5rem;
  }
  .remove__text {
    font-size: 1rem;
  }
}
</style>
