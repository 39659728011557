<template>
  <v-textarea
     v-bind="$props"
    class="input-area mb-5 mb-lg-10"
    v-model="model"
    solo
    hide-details="auto"
  >
  </v-textarea>
</template>

<script>

  export default {
    name: 'TextArea',
    props: {
      value: {
        type: String,
        default: ''
      },
      disabled: {
        type: Boolean,
        default: false
      }
    },
    computed: {
      model: {
        get() {
          return this.value;
        },
        set(value) {
          this.$emit('input', value);
        }
      }
    },
  }
</script>

<style scoped>
  @media only screen and (min-width: 601px) and (max-width: 960px) {
     .input-area >>> .v-text-field__slot {
      font-size: 1.5rem;
    }
  }
  @media only screen and (max-width: 600px) {
    .input-area >>> .v-text-field__slot {
      font-size: 1.2rem;
    }
  }
</style>
