<template>
  <v-container class="pa-0" fluid>
<!--    <v-row class="d-none d-lg-block mb-10">-->
<!--      <div class="header">-->
<!--        {{ $t('jammers.newJammers') }}-->
<!--      </div>-->
<!--    </v-row>-->
    <component
        v-for="(input, k) in inputs" :key="k"
        :is="(input.status === '' ? 'JammersForm': 'JammersRow')"
        :input="input"
        :k="k"
        @invite="handleInvite"
        @remove="handleRemove"
        @editJammer="handleEdit"
    />
    <v-row class="d-none d-lg-block">
      <v-col lg="10" cols="12" class="px-1">
        <v-btn
            class="btn mb-5 mb-lg-10"
            color="secondary"
            block
            @click="addCollaborator"
        >
          <img class="ma-2" :src="addIcon" alt="add icon"/>
          <span class="btn__text btn__text--white">{{ $t('jammers.inviteJammer') }}</span>
        </v-btn>
      </v-col>
    </v-row>
    <v-card class="floating d-lg-none" elevation=4>
      <v-container>
        <v-row>
          <v-col cols="12">
            <v-btn
                class="btn mb-5 mb-lg-10"
                color="secondary"
                block
                @click="addCollaborator"
            >
              <img class="ma-2" :src="addIcon"/>
              <span class="btn__text btn__text--white">{{ $t('jammers.inviteJammer') }}</span>
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </v-container>
</template>

<script>

import JammersRow from '@/components/JammersRow';
import JammersForm from '@/components/JammersForm';

export default {
  name: 'JammerFields',
  components: {
    JammersRow,
    JammersForm
  },
  props: {
    jammers: {
      type: Array
    }
  },
  data() {
    return {
      inputs: [...this.jammers],
      status: 'Sent',
      text: false
    }
  },
  computed: {
    addIcon() {
      return require('@/assets/Add_Icon.svg')
    }
  },
  methods: {
    addCollaborator() {
      console.log('addCollaborator');
      this.inputs.push({ firstName: '', lastName: '', phoneNumber: '', status: '' })
    },
    handleInvite(data) {
      this.$emit('invite', data);
    },
    handleRemove(id) {
      this.$emit('remove', id);
    },
    handleEdit(id) {
      this.$emit('editJammer', id);
    },
    clickHandler(which) {
      if (this.inputs[which].firstName !== '' &&
          this.inputs[which].lastName !== '' &&
          this.inputs[which].phoneNumber !== '') {
        this.$emit('invite', this.inputs[which]);
      } else {
        console.log('bad');
      }
    }
  },
  watch: {
    jammers: function(newVal) {
      this.inputs = [...newVal];
    }
  }
}
</script>

<style scoped>
.floating {
  position: fixed;
  bottom: 0;
  width: 100%;
  left: 0;
  z-index: 100;
}
.header {
  font-size: 2rem;
  text-decoration: underline;
  font-weight: bold;
  text-transform: uppercase;
}
.remove__text {
  color: var(--v-primaryWarning-base);
  text-transform: uppercase;
  text-align: center;
  font-weight: bold;
  text-decoration: underline;
  font-size: 1.2rem;
}
.field-number {
  font-size: 2rem;
  font-weight: bold;
}
.field-number--underlined {
  text-decoration: underline;
}
.input__field >>> .v-text-field__slot {
  font-size: 1.2rem;
  padding-left: 0;
  padding-right: 0;
}
@media only screen and (max-width: 960px) {
  .field-number {
    font-size: 1.5rem;
  }
  .remove__text {
    font-size: 1rem;
  }
}
</style>
