<template>
  <v-container fluid class="pa-0">
    <div style="display: block; text-align: center;">
      <h5 class="header__title_bold">Jammers</h5>
    </div>
    <v-row class="d-flex flex-column">
      <v-col class="header__text">
        <div class="header__text_bold">Inviting your Jammers is simple.</div>
          You can invite in two ways.
          <ol>
            <li>
              Enter their name and phone number below to send them an Invite from your Jam phone number, or…
            </li>
            <li>
              Simply have them text JAM to your Jam phone number to get started.
            </li>
          </ol>
        <div class="header__text_bold">Either way works great!</div>
            If you want to use your Jam phone number to send the invitation, click Invite a New Jammer, enter their name and phone number, and click Invite. They'll receive the invitation, opt-in, and they'll be added to your Jammers list below!
      </v-col>
      <v-col>
        <TextArea
            :disabled="true"
            v-model="textArea"
        />
      </v-col>
      <v-col>
        <JammersList
            :jammers="jammers"
            @invite="previewInvite"
            @editJammer="onEditJammer"
        />
      </v-col>
    </v-row>
    <v-dialog
        v-model="confirmDeleteDialog"
        max-width="450"
    >
      <ConfirmDialog
          title="Are you sure you want to delete this jammer?"
          subtitle=""
          :loading="api.loading"
          @confirm="onDelete"
          @cancel="confirmDeleteDialog = false"
        />
    </v-dialog>
    <v-dialog
      v-model="dialogEdit"
      max-width="450"
      >
      <v-card>
        <v-card-title>Edit Jammer</v-card-title>
        <v-card-text>
          <v-row>
            <v-col>
              <TextField
                  label="First Name"
                  ref="firstName"
                  autofocus="autofocus"
                  :placeholder="$t('jammers.firstName')"
                  v-model="selectedJammer.firstName"
                  :rules="[v => !!v || 'First name is required']"
              />
            </v-col>
            <v-col>
              <TextField
                  label="Last Name"
                  ref="lastName"
                  autofocus="autofocus"
                  :placeholder="$t('jammers.lastName')"
                  v-model="selectedJammer.lastName"
                  :rules="[v => !!v || 'First name is required']"
              />
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="onSave">Save</v-btn>
          <v-btn color="red" @click="confirmDelete">
            <span style="color: #ffffff">Delete Jammer</span></v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="previewDialog"
      max-width="450"
      >
      <v-card>
        <v-card-title class="text-h5">
          Invite Preview
        </v-card-title>
        <v-card-text>
          <PhoneTop
            :message="inviteMessage"
            :img="{src: info, width:'350'}"
          />
        </v-card-text>
        <v-card-actions>
          <v-spacer/>
          <v-btn
            color="darken-1"
            text
            @click="previewDialog = false"
            >Cancel</v-btn>
          <v-btn
            color="primary"
            @click="handleInvite"
            >Send</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar
        :color="notify.color"
        elevation="24"
        min-width="none"
        height="50"
        v-model="notify.visible">
      <div class="snack-text">
        <!--        <v-icon large class="pr-3">mdi-check-circle</v-icon>-->
        <v-icon large class="pr-3" v-if="notify.icon">{{  notify.icon }}</v-icon>
        {{ notify.text }}
      </div>
    </v-snackbar>
  </v-container>
</template>

<script>
import TextArea from '@/components/TextArea';
import JammersList from '@/components/JammersList';
import Http from '@/api/Http';
import { getterNames } from '@/store/global';
import PhoneTop from '@/components/PhoneTop';
import TextField from '@/components/TextField';
import { ConfirmDialog } from '@/components/dialogs';

export default {
  name: 'JammersCard',
  components: {
    TextArea,
    TextField,
    JammersList,
    PhoneTop,
    ConfirmDialog
  },
  props: {
    jam: {
      type: Object,
    }
  },
  data() {
    return {
      textArea: this.$t('jammers.textAreaPlaceholder'),
      api: Http.use('data'),
      jammers: [],
      confirmDeleteDialog: false,
      removeId: '',
      previewDialog: false,
      newJammer: {},
      inviteMessage: '',
      dialogEdit: false,
      selectedJammerId: null,
      notify: {
        visible: false,
        icon: null,
        text: null,
        color: null
      }
    }
  },
  computed: {
    invitePrepared() {
      return this.textArea;
    },
    currentUser() {
      return this.$store.getters[getterNames.currentUser];
    },
    info() {
      return require('@/assets/infographic.jpeg')
    },
    selectedJammer() {
      if (this.jammers[this.selectedJammerId]) {
        return this.jammers[this.selectedJammerId];
      } else {
        return {
          firstName: '',
          lastName: ''
        }
      }
    }
  },
  created() {
    this.textArea = this.jam.inviteMessage || this.$t('jammers.textAreaPlaceholder');
    this.getJammers();
  },
  methods: {
    onSave() {
      this.api.put(`/jams/${this.$route.params.id}/jammers/update`, {phone: this.selectedJammer.phoneNumber, firstName: this.selectedJammer.firstName, lastName: this.selectedJammer.lastName})
          .then(() => {
            this.dialogEdit = false;
            this.notify = {
              visible: true,
              color: 'success',
              text: 'Jammer updated',
              icon: 'mdi-check-circle'
            }
          })
          .catch(err => {
            if (err.response) {
              this.notify = {
                visible: true,
                color: 'red accent-2',
                text: err.response.data.error,
                icon: 'mdi-alert-circle-outline'
              }
            }
          })
    },
    onEditJammer(id) {
      this.selectedJammerId = id;
      this.dialogEdit = true;
    },
    getJammers () {
      this.api.get(`/jams/${this.$route.params.id}/jammers`)
          .then(jamrs => {
            this.jammers = jamrs.jammers;
            this.textArea = jamrs.invite;
          })
          .catch(err => {
            console.log(err);
          });
    },
    confirmDelete() {
      if (this.selectedJammerId === 0) {
        this.notify = {
          visible: true,
          color: 'red accent-2',
          text: 'Hey Boss! You cannot delete yourself.',
          icon: 'mdi-alert-circle-outline'
        }
      } else if (this.selectedJammer.status === 'deleted') {
        this.notify = {
          visible: true,
          color: 'red accent-2',
          text: 'Jammer is already deleted.',
          icon: 'mdi-alert-circle-outline'
        }
      } else {
        console.log(this.selectedJammer);
        this.confirmDeleteDialog = true;
      }
    },
    onDelete() {
      if (this.selectedJammer.locked) {
        this.api.put(`/jams/${this.$route.params.id}/jammers/update`, {phone: this.selectedJammer.phoneNumber, status: 'deleted'})
            .then(() => {
              this.dialogEdit = false;
              this.confirmDeleteDialog = false;
              this.notify = {
                visible: true,
                color: 'success',
                text: 'Jammer updated',
                icon: 'mdi-check-circle'
              }
            })
            .catch(err => {
              if (err.response) {
                this.notify = {
                  visible: true,
                  color: 'red accent-2',
                  text: err.response.data.error,
                  icon: 'mdi-alert-circle-outline'
                }
              }
            })
      } else {
        this.api.delete(`/jams/${this.$route.params.id}/jammers/${this.selectedJammer.phoneNumber}`)
            .then(() => {
              this.getJammers();
              this.confirmDeleteDialog = false;
              this.dialogEdit = false;
            })
            .catch(err => {
              console.log(err);
            })
      }

    },
    previewInvite(inputs) {
      this.newJammer = inputs;
      this.previewDialog = true;
      this.inviteMessage = this.textArea;

      this.inviteMessage = this.inviteMessage
          .replace('{JAMMER_FIRSTNAME}', inputs.firstName)
          .replace('{JAMBOSS_FIRSTNAME}', this.currentUser.firstName)
          .replace('{JAMBOSS_LASTNAME}', this.currentUser.lastName)
    },
    handleInvite() {
      this.previewDialog = false;
      this.newJammer.message = this.inviteMessage;
      this.newJammer.inviteMessage = this.textArea;
      if (this.newJammer.phoneNumber.slice(0,2) !== '+1') {
        this.newJammer.phoneNumber = '+1' + this.newJammer.phoneNumber.replace(/[^\d]/g, '');
      }
      this.api.post(`/jams/${this.$route.params.id}/jammers/invite`, this.newJammer)
          .then(() => {
            this.getJammers();
            this.notify = {
              visible: true,
              color: 'success',
              text: 'Invite sent',
              icon: 'mdi-check-circle'
            }
          })
          .catch(err => {
            this.notify = {
              visible: true,
              color: 'red accent-2',
              text: err.response.data.error,
              icon: 'mdi-alert-circle-outline'
            }
          });
    }
  }
}

</script>

<style scoped>
.header__text, ol, li {
  font-size: 1.5rem;
  color: var(--v-primary-base);
  text-align: left;
}
.header__text_bold {
  font-size: 2rem;
  color: var(--v-primary-base);
  text-align: left;
  font-weight: bold;
}
@media only screen and (max-width: 1264px) {
  .header__text {
    font-size: 1.5rem;
    text-align: center;
  }
}
.snack-text {
  font-size: 1.6rem;
  text-align: center;
  display: block;
  /*margin: 10px;*/
  margin-top: 3px;
  color: #ffffff;
}
</style>
