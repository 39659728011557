import { render, staticRenderFns } from "./JammersList.vue?vue&type=template&id=3536b0e7&scoped=true&"
import script from "./JammersList.vue?vue&type=script&lang=js&"
export * from "./JammersList.vue?vue&type=script&lang=js&"
import style0 from "./JammersList.vue?vue&type=style&index=0&id=3536b0e7&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3536b0e7",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VBtn,VCard,VCol,VContainer,VRow})
