<template>
  <div>
    <v-row class="d-flex align-center">
      <v-col lg="5" md="6" cols="12" class="px-1 field-number">
        {{ input.firstName }} {{ input.lastName }}
      </v-col>
      <v-col lg="3" md="6" cols="12" class="px-1 field-number">
        {{ phone }}
      </v-col>
      <v-col lg="2" md="6" cols="12" class="px-1">
        <JammerStatusBtn
            @invite="clickHandler(k)"
            :status="input.status"
            :disabled="true"
        />

      </v-col>
      <v-col lg="2" md="6" cols="12" class="px-1">
        <v-btn class="btn outlined block" @click="$emit('editJammer', k)" text>
          <span class="remove__text" v-html="$t('jammers.editJammer')"></span>
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <strong>Current prompt:</strong> {{ input.currentPrompt }}
      </v-col>

    </v-row>

  </div>
</template>
<script>
import JammerStatusBtn from '@/components/JammerStatus';
import { getFormattedPhone } from '@/lib';

export default {
  components: {
    JammerStatusBtn
  },
  props: {
    input: {
      type: Object,
      default: () => ({ firstName: '', lastName: '', phoneNumber: '', status: '' })
    },
    k: {
      type: Number
    }
  },
  data() {
    return {}
  },
  computed: {
    phone() {
      return getFormattedPhone(this.input.phoneNumber);
    }
  },
  methods: {
    deleteCollaborator() {
      this.$emit('edit', this.k);
    }
  }
}
</script>
<style>
.remove__text {
  color: var(--v-primaryWarning-base);
  text-transform: uppercase;
  text-align: center;
  font-weight: bold;
  text-decoration: underline;
  font-size: 1.2rem;
}
.field-number {
  font-size: 2rem;
  font-weight: bold;
}
.input__field >>> .v-text-field__slot {
  font-size: 1.2rem;
  padding-left: 0;
  padding-right: 0;
}
@media only screen and (max-width: 960px) {
  .field-number {
    font-size: 1.5rem;
  }
  .remove__text {
    font-size: 1rem;
  }
}
</style>
